<section
  *ngIf="messagesStore.currentBlock() as block; else waitingNotification"
  [id]="block.htmlId"
  [attr.aria-hidden]="block.temporary || !availableForAria()"
  data-testid="current-block"
>
  <ng-container *ngFor="let message of messagesStore.messages(); let isCurrent = last">
    <article
      *ngIf="asUserText(message) as userTextMessage"
      [id]="userTextMessage.metadata.htmlId"
      [userMessage]="userTextMessage"
      class="mt-2 mb-4"
      data-testid="user-text-message"
    ></article>

    <div *ngIf="hasBotAvatar(message)" class="d-flex align-items-end">
      <img
        botAvatar
        alt="-"
        class="mb-3"
        [ngClass]="{ invisible: message.metadata.position !== 'end' }"
        data-testid="bot-avatar-before-message"
      />

      <article
        *ngIf="asBotText(message) as botTextMessage"
        [id]="botTextMessage.metadata.htmlId"
        [botMessage]="botTextMessage"
        [isCurrent]="isCurrent"
        class="mb-3 me-5"
        data-testid="bot-text-message"
      ></article>

      <img
        *ngIf="asImage(message) as imageMessage"
        [message]="imageMessage"
        [id]="imageMessage.metadata.htmlId"
        [scrollOnLoad]="imageMessage.metadata.htmlId"
        alt="image"
        class="my-3"
        data-testid="image-message"
      />
      <fof-carousel
        *ngIf="asCarousel(message) as carouselMessage"
        [id]="carouselMessage.metadata.htmlId"
        [carouselMessage]="carouselMessage"
        class="mb-1"
        data-testid="carousel-message"
      >
      </fof-carousel>
    </div>

    <fof-suggestions-menu
      *ngIf="asSuggestions(message) as suggestionsMessage"
      [id]="suggestionsMessage.metadata.htmlId"
      [message]="suggestionsMessage"
      class="mb-1"
      data-testid="suggestions-menu"
    />

    <fof-satisfaction-level
      *ngIf="asSatisfactionLevel(message) as satisfactionLevelMessage"
      [id]="satisfactionLevelMessage.metadata.htmlId"
      [message]="satisfactionLevelMessage"
      class="my-4"
      data-testid="satisfaction-level"
    />

    <fof-satisfaction-emoji
      *ngIf="asSatisfactionEmojis(message) as emojisSatisfactionMessage"
      [id]="emojisSatisfactionMessage.metadata.htmlId"
      [message]="emojisSatisfactionMessage"
      class="my-4"
      data-testid="satisfaction-emoji"
    />
    <fof-satisfaction-comment
      *ngIf="asSatisfactionComment(message) as commentSatisfactionMessage"
      [id]="commentSatisfactionMessage.metadata.htmlId"
      [message]="commentSatisfactionMessage"
      class="my-4"
      data-testid="satisfaction-comment"
    />
  </ng-container>

  <ng-container *ngIf="messagesStore.messages() as messages">
    <!-- Displayed if some messages are yet to be displayed -->
    <fof-bot-typing-indicator
      *ngIf="conversationLocked() || messages.length < block.messages.length"
      aria-hidden="true"
      class="mt-2 pb-5"
      data-testid="typing-indicator"
    >
    </fof-bot-typing-indicator>

    <!-- Wait all messages to be displayed before displaying evaluation -->
    <!-- prettier-ignore -->
    <fof-answer-evaluation
      *ngIf="block.evaluationQuestionnaire && (messages.length === block.messages.length)"
      [data]="block.evaluationQuestionnaire"
    >
    </fof-answer-evaluation>
  </ng-container>
</section>
<ng-template #waitingNotification>
  <fof-bot-typing-indicator
    aria-hidden="true"
    class="mt-2 pb-5"
    data-testid="typing-indicator-waiting-notif"
  ></fof-bot-typing-indicator>
</ng-template>
