import { Directive, HostBinding } from "@angular/core";

/** A point button with specific styles in carousel for example*/
@Directive({
  selector: "button[point]",
  standalone: true,
})
export class PointButtonDirective {
  @HostBinding("style") hostStyles =
    "width: 0.7em; height: 0.7em; --bs-btn-bg: var(--fof-tertiary-color); --bs-btn-border-color: var(--fof-tertiary-color);";
  @HostBinding("class") hostClasses = "btn btn-primary rounded-circle p-0 m-1";
}
