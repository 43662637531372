import { ChangeDetectionStrategy, Component, HostBinding, Input } from "@angular/core";
import { IconButtonComponent } from "app/core/ui";
import { NgClass, NgFor, NgIf } from "@angular/common";
import { FormButtonComponent } from "app/core/ui/form-button-component";
import { CarouselSlideComponent } from "../carousel-slide-component";
import { CarouselMessage } from "app/chat/common/message.model";
import { ScrollOnSlideDirective } from "../scroll-on-slide.directive";
import { PointButtonDirective } from "app/core/ui/point-button-directive";

/**
 * Displays a carrousel with differents slides
 */
@Component({
  selector: "fof-carousel",
  templateUrl: "./template.html",
  standalone: true,
  imports: [
    NgClass,
    NgFor,
    NgIf,
    IconButtonComponent,
    FormButtonComponent,
    CarouselSlideComponent,
    ScrollOnSlideDirective,
    PointButtonDirective,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselComponent {
  protected message!: CarouselMessage;

  @HostBinding("class") hostClasses: { [klass: string]: any } = {};

  @Input()
  set carouselMessage(newValue: CarouselMessage) {
    this.message = newValue;
    this.hostClasses = {
      "d-block": true,
      "mb-1": newValue.metadata.position !== "end",
      "mb-3": newValue.metadata.position === "end",
      "overflow-hidden": true,
    };
  }

  /** Disable suggestions buttons when it is history message*/
  @Input() allowInteraction = false;

  protected activeSlideId: number = 0;
  protected buttonSlideTitle = $localize`:@@carousel.goToSlide.title:`;

  /** Styles to be applied to previous and next buttons */
  protected buttonsStyles = {
    color: "var(--fof-primary-color)",
    bgColor: "rgba(var(--fof-primary-color-rgb), 0.08)",
    borderColor: "var(--fof-primary-color)",
    colorOnHover: "var(--fof-primary-bg-text-color)",
    bgColorOnHover: "var(--fof-primary-color)",
    borderColorOnHover: "var(--bs-btn-border-color)",
  };

  activateSlide(index: number) {
    this.activeSlideId = index;
  }
}
