import { ChangeDetectionStrategy, Component, HostBinding, Input } from "@angular/core";
import { TextExecutor } from "./text.executor";
import { MessagePositionType, BotTextMessage } from "../message.model";
import { NgIf, NgClass, NgStyle } from "@angular/common";
import { CleanHtmlPipe } from "app/core";
import { IconizePipe } from "app/core/util/iconize.pipe";
import { ToActionsPipe } from "./to-actions.pipe";

/**
 * Displays a text message issued by the chatbot.
 * Style is adapted in the following way:
 * - Corners display is adapted to the position of the message in the sequence of messages (but with no tail)
 * - a11y required for links (new page opened in tab)
 * - a11y required for actions (span with class), that trigger a command in the application
 * - Displayed on the left with a left spacing allowing to display the bot icon on the last message of a sequence
 * - Background color: soft color
 */
@Component({
  selector: "article[botMessage]",
  templateUrl: "./template.html",
  standalone: true,
  imports: [NgIf, NgClass, NgStyle, CleanHtmlPipe, IconizePipe, ToActionsPipe],
  providers: [TextExecutor],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BotTextMessageComponent {
  /** @return The text to be displayed. May contain HTML markup */
  protected text = "";
  protected position!: MessagePositionType;
  /**
   * The classes to be applied to the host element, overriding any classes
   * defined in the parent component
   */
  @HostBinding("class") hostClasses: { [klass: string]: any } = {};
  @HostBinding("attr.aria-label") hostAriaLabel = $localize`:@@chat.message:`;

  /** Whether this message is currently the last one being displayed */
  @Input()
  public isCurrent = false;

  constructor(private textExecutor: TextExecutor) {}

  @Input()
  set botMessage(newValue: BotTextMessage) {
    this.text = newValue.text;
    this.position = newValue.metadata.position;
    this.hostClasses = {
      "d-flex": true,
      "align-items-end": true,
      "mb-1": this.position !== "end",
      "mb-3": this.position === "end",
    };
  }

  /**
   * Called when the message is from the chatbot.
   * Extracts the class of the target of the passed event, and if it exists,
   * invokes the text executor that checks if the class corresponds to the ID
   * of an action that is supported and if so, executes the corresponding action.
   */
  onSelection(event: Event) {
    event.stopPropagation();
    const target = event.target as HTMLElement | null;
    const action = target?.getAttribute("value");
    if (action) {
      this.textExecutor.execute(action);
    }
  }
}
