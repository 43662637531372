import { ChangeDetectionStrategy, Component, Signal } from "@angular/core";
import {
  ImageMessage,
  ConversationMessage,
  BotTextMessage,
  UserTextMessage,
  HistoryMessage,
  CarouselMessage,
} from "../../common/message.model";
import { HistoryStore } from "../history.store";
import { NgClass, NgFor, NgIf } from "@angular/common";
import { BotTextMessageComponent } from "app/chat/common/bot-text-message-component";
import { UserTextMessageComponent } from "app/chat/common/user-text-message-component";
import { ImageMessageDirective } from "app/chat/common/image-message.directive";
import { BotAvatarDirective } from "app/chat/common/bot-avatar.directive";
import { CarouselComponent } from "app/chat/current/carousel/carousel-component";

/**
 * A component displaying all the text and image messages that were part of the
 * conversation, before the current block.
 */
@Component({
  selector: "fof-history",
  templateUrl: "./template.html",
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgFor,
    NgIf,
    NgClass,
    BotTextMessageComponent,
    UserTextMessageComponent,
    ImageMessageDirective,
    BotAvatarDirective,
    CarouselComponent,
  ],
})
export class HistoryComponent {
  protected history: Signal<readonly HistoryMessage[]>;

  constructor(public historyStore: HistoryStore) {
    this.history = this.historyStore.history;
  }

  /** @return The passed message if it is an instance of a BotTextMessage */
  asBotText(message: ConversationMessage): BotTextMessage | null {
    return message instanceof BotTextMessage ? message : null;
  }
  /** @return The passed message if it is an instance of a UserTextMessage */
  asUserText(message: ConversationMessage): UserTextMessage | null {
    return message instanceof UserTextMessage ? message : null;
  }
  /** @return The passed message if it is an instance of a ImageMessage */
  asImage(message: ConversationMessage): ImageMessage | null {
    return message instanceof ImageMessage ? message : null;
  }
  /** @return The passed message if it is an instance of a CarouselMessage */
  asCarousel(message: ConversationMessage): CarouselMessage | null {
    return message instanceof CarouselMessage ? message : null;
  }

  /** @return Whether it is a message which could have bot avatar displayed before */
  hasBotAvatar(message: ConversationMessage): boolean {
    return (
      message instanceof ImageMessage ||
      message instanceof BotTextMessage ||
      message instanceof CarouselMessage
    );
  }
}
