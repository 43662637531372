<span class="visually-hidden" i18n="@@chat.message.from.bot.visually-hidden">-</span>
<span
  class="d-block p-2 rounded-3 fs-6 lh-sm text-break"
  style="background-color: var(--fof-secondary-bg-color)"
  [ngClass]="{
    'rounded-top-start-1': position !== 'start',
    'rounded-bottom-start-1': this.position !== 'end'
  }"
  (click)="onSelection($event)"
  (keydown.enter)="onSelection($event)"
  [innerHtml]="text | cleanhtml | iconize | toActions"
  data-testid="bot-message"
></span>
