import { Injectable, Signal, signal } from "@angular/core";
import {
  BotTextMessage,
  CarouselMessage,
  CurrentConversationBlock,
  HistoryMessage,
  ImageMessage,
  UserTextMessage,
} from "../common/message.model";
import { IncomingRouter } from "../incoming";

/** The store for all the past messages issued during the current conversation */
@Injectable({ providedIn: "root" })
export class HistoryStore {
  /** The current block of the conversation */
  private _currentBlock?: CurrentConversationBlock;

  /** The array of all the displayable past messages */
  private _history = signal<readonly HistoryMessage[]>([]);

  constructor(incomingRouter: IncomingRouter) {
    incomingRouter.conversationBlocks$.subscribe((block: CurrentConversationBlock) => {
      if (this._currentBlock) {
        const existingBlock = this._currentBlock;
        this._history.update((value) => [
          ...value,
          ...existingBlock.messages
            .filter(
              (message) =>
                message instanceof UserTextMessage ||
                message instanceof BotTextMessage ||
                message instanceof ImageMessage ||
                message instanceof CarouselMessage
            )
            .map(
              (message) =>
                message as UserTextMessage | BotTextMessage | ImageMessage | CarouselMessage
            ),
        ]);
      }
      this._currentBlock = block;
    });
  }

  /**
   * @return All the displayable messages of the conversation but those from the current
   *         conversation block
   */
  get history(): Signal<readonly HistoryMessage[]> {
    return this._history;
  }

  /**
   * @return All the text messages of the conversation including those from the current
   *         conversation block
   */
  get textHistory(): Array<BotTextMessage | UserTextMessage> {
    return this._currentBlock
      ? [...this._history(), ...this._currentBlock.messages]
          .filter(
            (message) => message instanceof BotTextMessage || message instanceof UserTextMessage
          )
          .map((message) => message as BotTextMessage | UserTextMessage)
      : []; // no past messages exist, if no current conversation block is found
  }

  /**
   * Resets the store, clearing all past messages.
   * Does not invalidate the current subscriptions to the past messages.
   */
  public clear(): void {
    this._history.set([]);
    this._currentBlock = undefined;
  }
}
