import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Inject,
  Signal,
  computed,
  signal,
} from "@angular/core";
import { NgFor, NgIf } from "@angular/common";
import { IconButtonComponent } from "app/core/ui";
import { APP_CONFIG, AppConfig } from "app/core";

/** The personal data message, displayed at the top of the box messages */
@Component({
  selector: "fof-personal-data-message",
  templateUrl: "./template.html",
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, NgFor, IconButtonComponent],
})
export class PersonalDataMessageComponent {
  protected readonly text: Signal<string>;
  protected shouldShow = computed(() => this.text());

  @HostBinding("class.d-none") get shouldHide() {
    return !this.shouldShow();
  }

  constructor(@Inject(APP_CONFIG) appConfig: AppConfig) {
    if (appConfig.personalDataMessage) {
      this.text = appConfig.personalDataMessage.text;
    } else {
      this.text = signal("");
    }
  }
}
