import { ChangeDetectionStrategy, Component, Inject, Signal, signal } from "@angular/core";
import { NgIf, NgFor, NgClass } from "@angular/common";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { delay, filter, tap } from "rxjs";
import { MessagesStore } from "../messages.store";
import {
  SatisfactionCommentComponent,
  SatisfactionEmojiComponent,
  SatisfactionLevelComponent,
} from "../satisfaction";
import { CONVERSATION_LOCK } from "../../di-tokens";
import { APP_CONFIG, AppConfig, LockUtil } from "app/core";
import { AnswerEvaluationComponent } from "../answer-evaluation-component";
import { SuggestionsMenuComponent } from "../suggestion";
import { UserTextMessageComponent } from "app/chat/common/user-text-message-component";
import { BotTextMessageComponent } from "app/chat/common/bot-text-message-component";
import { ImageMessageDirective } from "app/chat/common/image-message.directive";
import { BotTypingIndicatorComponent } from "app/chat/common/bot-typing-indicator-component";
import {
  BotTextMessage,
  ConversationMessage,
  SatisfactionEmojisMessage,
  SatisfactionCommentMessage,
  ImageMessage,
  SatisfactionLevelMessage,
  SuggestionsMessage,
  CarouselMessage,
} from "app/chat/common/message.model";
import { UserTextMessage } from "app/chat";
import { ScrollOnLoadDirective } from "app/chat/common/scroll-onload.directive";
import { BotAvatarDirective } from "app/chat/common/bot-avatar.directive";
import { CarouselComponent } from "../carousel/carousel-component";

/**
 * A component displaying the messages available in the lastly received conversation block.
 * Accessibility: The screen reader should read the whole block on new a current block,
 * but only new messages, when they are displayed progressively for a new block. Temporary
 * blocks are ignored.
 */
@Component({
  selector: "fof-current-conversation-block",
  templateUrl: "./template.html",
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    NgFor,
    NgClass,
    UserTextMessageComponent,
    BotTextMessageComponent,
    ImageMessageDirective,
    SuggestionsMenuComponent,
    SatisfactionLevelComponent,
    SatisfactionEmojiComponent,
    SatisfactionCommentComponent,
    AnswerEvaluationComponent,
    BotTypingIndicatorComponent,
    ScrollOnLoadDirective,
    BotAvatarDirective,
    CarouselComponent,
  ],
})
export class CurrentConversationBlockComponent {
  protected readonly conversationLocked: Signal<boolean>;
  protected readonly availableForAria = signal<boolean>(false);

  constructor(
    @Inject(APP_CONFIG) appConfig: AppConfig,
    public messagesStore: MessagesStore,
    @Inject(CONVERSATION_LOCK) public conversationLock: LockUtil
  ) {
    this.conversationLocked = this.conversationLock.locked;

    // required by screen readers so that they can read the new block messages
    // after the focus on the user input was made. If you modify this, you MUST check
    // the resulting behavior with a screen reader.
    messagesStore.currentBlock$
      .pipe(
        tap((block) => this.availableForAria.set(!block.expectsUserText())),
        filter((block) => block.expectsUserText()),
        delay(appConfig.currentBlockAriaVisibleAfter),
        tap(() => this.availableForAria.set(true)),
        takeUntilDestroyed()
      )
      .subscribe();
  }

  /** @return The passed message if it is an instance of a UserTextMessage and a user message */
  asUserText(message: ConversationMessage): UserTextMessage | null {
    return message instanceof UserTextMessage ? message : null;
  }
  /** @return The passed message if it is an instance of a TextMessage and a bot message */
  asBotText(message: ConversationMessage): BotTextMessage | null {
    return message instanceof BotTextMessage ? message : null;
  }
  /** @return The passed message if it is an instance of a ImageMessage */
  asImage(message: ConversationMessage): ImageMessage | null {
    return message instanceof ImageMessage ? message : null;
  }
  /** @return The passed message if it is an instance of a SuggestionsMessage */
  asSuggestions(message: ConversationMessage): SuggestionsMessage | null {
    return message instanceof SuggestionsMessage ? message : null;
  }
  /** @return The passed message if it is an instance of a SatisfactionLevelMessage */
  asSatisfactionLevel(message: ConversationMessage): SatisfactionLevelMessage | null {
    return message instanceof SatisfactionLevelMessage ? message : null;
  }
  /** @return The passed message if it is an instance of an EmojisSatisfactionMessage */
  asSatisfactionEmojis(message: ConversationMessage): SatisfactionEmojisMessage | null {
    return message instanceof SatisfactionEmojisMessage ? message : null;
  }
  /** @return The passed message if it is an instance of an FreeCommentSatisfactionMessage */
  asSatisfactionComment(message: ConversationMessage): SatisfactionCommentMessage | null {
    return message instanceof SatisfactionCommentMessage ? message : null;
  }

  /** @return The passed message if it is an instance of a CarouselMessage */
  asCarousel(message: ConversationMessage): CarouselMessage | null {
    return message instanceof CarouselMessage ? message : null;
  }

  /** @return Whether it is a message which could have bot avatar displayed before */
  hasBotAvatar(message: ConversationMessage): boolean {
    return (
      message instanceof ImageMessage ||
      message instanceof BotTextMessage ||
      message instanceof CarouselMessage
    );
  }
}
