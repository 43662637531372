import { Directive, ElementRef, Inject, Input } from "@angular/core";
import { AUTOSCROLL_BEHAVIOR } from "app/core/ui/di-tokens";

/** Special scroll directive to scroll to specific slide id in Carousel */
@Directive({
  selector: "div[scrollToSlide]",
  standalone: true,
})
export class ScrollOnSlideDirective {
  private element;

  constructor(
    el: ElementRef<HTMLInputElement>,
    @Inject(AUTOSCROLL_BEHAVIOR) private autoScrollBehavior: ScrollBehavior
  ) {
    this.element = el.nativeElement;
  }

  @Input() set scrollToSlide(index: number) {
    const scrollWidth = this.element.querySelector(`[carousel-slide='${index}']`)?.scrollWidth || 0;
    this.element.scrollTo({
      top: 0,
      left: scrollWidth * index,
      behavior: this.autoScrollBehavior,
    });
  }
}
