import { NgIf } from "@angular/common";
import { ChangeDetectionStrategy, Component, HostBinding, Input } from "@angular/core";
import { CarouselItem, UrlRedirect } from "app/chat/common/message.model";
import { Suggestion } from "app/chat/incoming/connector";
import { SuggestionComponent } from "../../suggestion";

/**
 * A slide of a carrousel
 */
@Component({
  selector: "div[slide]",
  templateUrl: "./template.html",
  standalone: true,
  imports: [NgIf, SuggestionComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselSlideComponent {
  @HostBinding("style") hostStyles =
    "min-width: 14rem; max-width: 14rem; scroll-snap-align: center;";
  @HostBinding("class") hostClasses = "border rounded-3 m-1 d-flex flex-column";

  @Input() slide!: CarouselItem;

  @HostBinding("attr.id") hostId = this.id;
  @HostBinding("attr.aria-label") hostAriaLabel = $localize`:@@carousel.slide.description:`;

  @Input() set id(newValue: string) {
    this.hostId = newValue;
  }

  /** Disable suggestions buttons when it is history message*/
  @Input() allowInteraction = false;

  isUrlRedirect(action: UrlRedirect | Suggestion | undefined): action is UrlRedirect {
    return (action as UrlRedirect).externalUrl !== undefined;
  }

  isSuggestion(action: UrlRedirect | Suggestion | undefined): action is Suggestion {
    return (action as Suggestion).intent !== undefined;
  }
}
