<div class="d-flex align-items-end">
  <div
    [scrollToSlide]="activeSlideId"
    class="d-flex flex-nowrap"
    style="overflow-x: hidden; scroll-snap-type: x proximity"
  >
    <ng-container *ngFor="let slide of message.items; let indexOfElement = index">
      <div
        [slide]="slide"
        [allowInteraction]="allowInteraction"
        [attr.carousel-slide]="indexOfElement"
        data-testid="slide"
      ></div>
    </ng-container>
  </div>
</div>

<div *ngIf="message.items.length > 1" class="d-flex justify-content-center align-items-center">
  <button
    icon="chevron-left"
    [etched]="buttonsStyles"
    [disabled]="activeSlideId === 0"
    title="-"
    i18n-title="@@carousel.gotToPreviousSlide.title"
    (click)="activateSlide(activeSlideId - 1)"
    data-testid="previous-slide-button"
    class="me-1"
  ></button>
  <ng-container *ngFor="let slide of message.items; let itemIndex = index">
    <button
      point
      [class.active]="itemIndex === activeSlideId"
      (click)="activateSlide(itemIndex)"
      [class.aria-current]="itemIndex === activeSlideId"
      [title]="buttonSlideTitle + itemIndex"
      data-testid="slide-button"
    ></button>
  </ng-container>
  <button
    icon="chevron-right"
    [etched]="buttonsStyles"
    (click)="activateSlide(activeSlideId + 1)"
    [disabled]="activeSlideId === message.items.length - 1"
    title="-"
    i18n-title="@@carousel.gotToNextSlide.title"
    data-testid="next-slide-button"
    class="ms-1"
  ></button>
</div>
